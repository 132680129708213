import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

var colorArr = ['#87c2a6', '#d2c689', '#e99025', '#f45f66', '#66FFFF', '#FF66FF']
var colorArrIndex = 0;

function MenuSquare(props){
    
    // Determining the background-color
    let color = colorArr[colorArrIndex];
    colorArrIndex += 1;

    let op;
    // Converting the value to a character for the operation to look sleeker
    switch(props.value){
        case('Addition'):
            op = "+";
            break;
        case('Subtraction'):
            op = "-";
            break;
        case('Multiplication'):
            op = 'x';
            break;
        case('Division'):
            op = '/';
            break;
        default:
            op = '';
            break;
    }

    return(
    <button className="menu-square " style={{ backgroundColor: color }}  onClick={ () => { props.onClick() } } >
            { op }
    </button>
    )
}

class Menu extends React.Component {

    constructor(props){
        super(props);
        this.state = { 
            selected : ''
        }
    }

    renderMenuSquare(MenuItem) {
        return ( 
            <MenuSquare value={ MenuItem } onClick = { () => this.handleClick(MenuItem) }/>
        );
    }

    handleClick(selection){
        ReactDOM.render(
            <Quiz value={ selection }/>,
            document.getElementById('root')
        );
    }

    render() {
        return (
          <div class='menu'>
            <div className="menu-row">
              {this.renderMenuSquare('Addition')}
              {this.renderMenuSquare('Subtraction')}
            </div>
            <div className="menu-row">
              {this.renderMenuSquare('Multiplication')}
              {this.renderMenuSquare('Division')}
            </div>
          </div>
        );
    }
}

class Quiz extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            operation : props.value,
            completed: 0,
            correct: 0,
            minimum: 1,
            maximum: 20,
            answerInput: '',
            resultColor: 'nullAnswer',
            resultContent: ''
        }

        this.calculate = this.calculate.bind(this);
        this.checkAnswer = this.checkAnswer.bind(this);
        this.answerChange = this.answerChange.bind(this);
        this.numericParamChange = this.numericParamChange.bind(this);

        this.calculate();
    }

    calculate(){
        // In case min or max isn't a number
        let min = isNaN(this.state.minimum) ? 1 : this.state.minimum;
        let max = isNaN(this.state.maximum) ? 1 : this.state.maximum;
        this.setState({
            minimum : min,
            maximum : max
        })

        let dif = max - min;
        this.a = Math.floor(Math.random() * dif) + min;
        this.b = Math.floor(Math.random() * dif) + min;
        // We don't want to risk dividing by zero
        this.b = Number(this.b) === Number(0) ? 1 : this.b;

        switch(this.state.operation){
            case 'Addition':
                this.operator = '+';
                this.correctAnswer = this.a + this.b;
                break;
            case 'Subtraction':
                this.operator = '-';
                this.correctAnswer = this.a - this.b;
                break;
            case 'Multiplication':
                this.operator = 'X';
                this.correctAnswer = this.a * this.b;
                break;
            case 'Division':
                this.operator = '/';
                this.correctAnswer = this.a / this.b;
                break;
            default:
                this.correctAnswer = 'Error';
                break;
        }
    }

    answerChange(event){
        this.setState({answerInput : event.target.value})
    }

    numericParamChange(event, param){
        console.log(event.target.value);
        let num = parseFloat(event.target.value);
        // In case param isn't a number
        num = isNaN(num) ? 1 : num;

        switch(param){
            case('minimum'):
                // Making sure the minimum is smaller than the maximum
                num = this.state.maximum <= num ? this.state.maximum - 1 : num;
                this.setState({minimum : num});
                break;
            case('maximum'):
                this.setState({maximum : num});
                break;
            default:
                break;
        }   
    }

    checkAnswer(event){
        let num = parseFloat(this.state.answerInput);
        // Rounds to hundreths place
        let correct = parseFloat(Math.round((parseFloat(this.correctAnswer) + Number.EPSILON) * 100) / 100);
        // Math.round((num + Number.EPSILON) * 100) / 100

        if(num === correct){
            this.setState({ correct : this.state.correct + 1,
                            resultColor : 'correctAnswer',
                            resultContent : 'Correct!' })
        }
        else{
            // Show the correct answer
            let contentString = 'Incorrect: ' + String(this.a) + ' ' + String(this.operator) + ' ' + 
                                String(this.b) + ' = ' + String(correct);
            this.setState({
                resultColor : 'incorrectAnswer',
                resultContent : contentString
            })
        }
        this.setState({ completed: this.state.completed + 1,
                        answerInput: '' })
        event.preventDefault();
        this.calculate();
    }

    render() {
        return(
            <div class="quiz-screen">
                <div class="heading">{ this.state.operation }</div>
                <div class="stats-table">
                    <div class="stats-heading">Stats</div>
                    <div class="stat">Completed: { this.state.completed }</div>
                    <div class="stat">Correct: { this.state.correct }</div>
                    <div class="stat">Percentage: { Math.round(this.state.correct / this.state.completed * 100) }%</div>
                </div>
                <div class="params">
                    <form onSubmit={this.changeParams}>
                        <div class="params-heading">Parameters</div>
                        <div class="param">Minimum:&nbsp;<input class="param-input" type="text"
                        value={ this.state.minimum } onChange={(event) => this.numericParamChange(event, 'minimum')}/></div>
                        <div class="param">Maximum:&nbsp;<input class="param-input" type="text"
                        value={ this.state.maximum } onChange={(event) => this.numericParamChange(event, 'maximum')}/></div>
                    </form>
                </div>
                <div class="question-box">
                    <form onSubmit={this.checkAnswer}>
                        <div class="question">
                            <span class="question-argument">{ this.a }</span>
                            <span class="operator">&nbsp;{ this.operator }&nbsp;</span>
                            <span class="question-argument">{ this.b }</span>
                            <span class="operator">&nbsp;=&nbsp;</span>
                            {/* Input for the answer */}
                            <input class="question-input" type="text" value={ this.state.answerInput } onChange={ this.answerChange }/>&nbsp;
                            {/* <input class="question-submit" type="submit" value="Check"/> */}
                        </div>
                    </form>
                    <div class={ this.state.resultColor } id="result">
                        { this.state.resultContent }
                    </div>
                </div>
            </div>
        )
    }
}


ReactDOM.render(
    <Menu />,
    document.getElementById('root')
);